import './subscription-confirmation.scss';

import React from 'react';

import Layout from 'components/layouts/Base';
import Logo from 'components/Logo';

const SubscriptionConfirmationPage: React.FC = () => {
  return (
    <Layout>
      <div className="thank-you">
        <h1 id="heading">
          We <Logo alt="heart" /> you.
        </h1>
        <p>
          You are now signed up for our friendly newsletter.
          <br />
          Thanks for caring! <a href="/">Back Home</a>
        </p>
      </div>
    </Layout>
  );
};

export default SubscriptionConfirmationPage;
