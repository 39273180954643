import './Logo.scss';

import React from 'react';

interface Props {
  alt?: string;
}

const Logo: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="40px"
      height="30px"
      viewBox="0 0 40 30"
      enableBackground="new 0 0 40 30"
      xmlSpace="preserve"
    >
      <title>{props.alt}</title>
      <rect
        className="square-3"
        x="0.858"
        y="7.929"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 15 -6.2132)"
        width="28.284"
        height="14.142"
      />
      <rect
        className="square-1"
        x="10.858"
        y="7.929"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.2843 22.0711)"
        width="28.284"
        height="14.142"
      />
      <rect
        className="square-2"
        x="12.929"
        y="12.929"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.2843 20)"
        width="14.142"
        height="14.142"
      />
    </svg>
  );
};

Logo.defaultProps = {
  alt: 'Friends of The Web Logo'
};

export default Logo;
